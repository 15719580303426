



































import { BtnType, CBtn } from "@/models/utils/c-btn.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CosmopolBtn extends Vue {
  @Prop({ required: false, default: null, type: String })
  private btnType: BtnType;
  @Prop({ required: false, default: null, type: Object })
  private customBtn: Omit<CBtn, "type">;

  private errorBtnOn = false;

  get item(): CBtn {
    switch (this.btnType) {
      case BtnType.SAVE:
        return {
          type: BtnType.SAVE,
          label: "Salva",
          labelClass: "c-label-light",
          color: "var(--v-success-darken3)",
          icon: {
            name: "check",
            fill: "var(--v-light-base)",
            height: 24,
            width: 24,
            iconStyle: "mb-1",
          },
        };
      case BtnType.CANCEL:
        return {
          type: BtnType.CANCEL,
          label: "Annulla",
          labelClass: "c-label-light",
          color: "var(--v-error-darken3)",
          icon: {
            name: "close",
            fill: "var(--v-light-base)",
            height: 28,
            width: 28,
            iconStyle: "mt-1",
          },
        };
      case BtnType.FILTER:
        return {
          type: BtnType.FILTER,
          label: "Filtra",
          labelClass: "c-label-light",
          color: "var(--v-primary-base)",
          icon: null,
        };
      case BtnType.ERRROR:
        return {
          type: BtnType.ERRROR,
          btnClass: "c-btn-outlined",
          label: "Errori",
          labelClass: "c-label-dark",
          color: "var(--v-primary-lighten5)",
          icon: {
            name: "",
            fill: "var(--v-primary-base)",
            height: 28,
            width: 28,
            iconStyle: "mt-2",
          },
        };
      case BtnType.ADDNOTE:
        return {
          type: BtnType.ADDNOTE,
          btnClass: "c-btn-outlined",
          label: "Aggiungi nota",
          labelClass: "c-label-dark",
          color: "var(--v-light-base)",
          icon: {
            name: "plus",
            fill: "var(--v-primary-base)",
            height: 24,
            width: 24,
            iconStyle: "mt-1",
          },
        };
      case BtnType.ARCHIVE:
        return {
          type: BtnType.ARCHIVE,
          btnClass: "c-btn-outlined",
          label: "Archivia",
          labelClass: "c-label-dark",
          color: "var(--v-light-base)",
          icon: {
            name: "archive",
            fill: "var(--v-primary-base)",
            height: 20,
            width: 20,
            iconStyle: "",
          },
        };
      default:
        return this.customBtn as CBtn;
    }
  }

  private onBtnClick(): void {
    if (this.item.type === BtnType.ERRROR) {
      this.errorBtnOn = !this.errorBtnOn;
      this.$emit("on-click", this.errorBtnOn);
    } else {
      this.$emit("on-click");
    }
  }
}
